var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "flex bg-white dim bw0 br2 ph3 pv2 pointer items-center ml1 mobile",
      on: {
        click: function ($event) {
          return _vm.signUp()
        },
      },
    },
    [
      _c("img", {
        staticClass: "adori-logo",
        attrs: {
          src: require("@/assets/adori-logo-dark.png"),
          height: "20",
          width: "20",
          alt: "adori-logo",
        },
      }),
      _c("div", { staticClass: "flex flex-column ml1" }, [
        _vm.loading
          ? _c("span", { staticClass: "loader loader-inline ml3 mr3" })
          : _c("span", [_vm._v("Sign Up")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }