var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.networksMenu.length > 1
    ? _c(
        "BasePopupMenu",
        {
          ref: "profileMenu",
          attrs: {
            icon: _vm.networksMenu.length > 1 ? "arrow_drop_down" : "",
            items: _vm.networksMenu.length > 1 ? _vm.networksMenu : [],
            isNetworksMenu: "",
          },
        },
        [
          _c("span", { staticClass: "f6 white bw0 mr1" }, [
            _vm.currentNetworkImage
              ? _c("img", {
                  staticClass: "br2 mr2 v-mid ba b--gray bw1",
                  attrs: { src: _vm.currentNetworkImage },
                })
              : _c(
                  "i",
                  {
                    staticClass:
                      "mr2 material-icons v-mid custom-size br2 ba b--gray bw2",
                  },
                  [_vm._v("account_box")]
                ),
          ]),
          _c(
            "span",
            { staticClass: "dn dib-l f6 light-gray mw5 truncate v-mid" },
            [_vm._v(_vm._s(_vm.currentNetworkName))]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }