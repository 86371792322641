var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isInvoicesLoading || _vm.isSummaryLoading || _vm.isCardLoading
      ? _c("div", {
          staticClass:
            "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size br2 mr3 bn",
        })
      : _c("div", { staticClass: "flex justify-center items-center" }, [
          _vm.creditBalanceAvailable !== 0
            ? _c(
                "div",
                { staticClass: "bg-adori-light-gray br2 pa1 mr3 ph2 silver" },
                [
                  _c(
                    "i",
                    { staticClass: "material-icons light-gray f5 v-mid pa1" },
                    [_vm._v("monetization_on")]
                  ),
                  _c("span", { staticClass: "f6" }, [
                    _vm._v(
                      "Free Credits Remaining: " +
                        _vm._s(_vm.creditBalanceAvailable) +
                        " / " +
                        _vm._s(_vm.lifetime_credits_given) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.cardAdded && _vm.creditBalanceAvailable === 0
            ? _c(
                "div",
                {
                  staticClass: "bg-red br2 pa2 mr3 ph3 f6 white pointer",
                  on: { click: _vm.addCard },
                },
                [_vm._v("\n      Add your card now for 5$ credit\n    ")]
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }