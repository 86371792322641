






















import { Component, Mixins } from 'vue-property-decorator'
import Billing from '@/mixins/Billing'

@Component
export default class TheCreditUsage extends Mixins(Billing) {}
