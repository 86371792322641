var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "signin flex br2 pointer items-center",
      on: {
        click: function ($event) {
          return _vm.signIn()
        },
      },
    },
    [
      _c("div", { staticClass: "flex flex-column ml5" }, [
        _vm.loading
          ? _c("span", { staticClass: "loader loader-inline ml3 mr3" })
          : _c("span", [_vm._v("Login")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }