








import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class TheAppSwitch extends Vue {
  @Getter selectedProduct!: string
  get popupMenuItems() {
    const blog = {
      name: 'Adori Text to Video',
      icon: 'article',
      disabled: this.selectedProduct === 'BLOG',
      onClick: () => {
        this.$store.commit('setSelectedProduct', 'BLOG')
        this.$router.push('/home/blog')
      },
    }
    const video = {
      name: 'Adori for Podcasters',
      icon: 'smart_display',
      disabled: this.selectedProduct === 'VIDEO',
      onClick: () => {
        this.$store.commit('setSelectedProduct', 'VIDEO')
        this.$router.push('/home')
      },
    }
    return [video, blog]
  }
}
