var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isUsageLoading
        ? _c("div", {
            staticClass:
              "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size br2 mr3 bn",
          })
        : _vm.isUpgradeRequired
        ? _c(
            "div",
            {
              staticClass: "bg-red br2 pa2 mr3 ph3 f6 white pointer",
              on: { click: _vm.openModal },
            },
            [_vm._v("\n    🌟 Upgrade to Premium\n  ")]
          )
        : _c(
            "BasePopupMenu",
            {
              staticClass:
                "dib relative mr3 bn f6 ph3 pv1 br2 ba b--adori-gray light-gray bg-dark-gray btn-shadow pointer",
              attrs: { icon: "arrow_drop_down", items: _vm.popupMenuItems() },
            },
            [
              _c(
                "i",
                { staticClass: "material-icons light-gray f5 v-mid pa1" },
                [_vm._v("monetization_on")]
              ),
              _c("span", { staticClass: "f6 v-mid" }, [
                _vm._v(" " + _vm._s(_vm.usageText)),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }