var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BasePopupMenu",
        {
          staticClass: "pointer",
          attrs: { items: _vm.popupMenuItems, position: "top-1 left-1" },
        },
        [
          _c("i", { staticClass: "material-icons light-gray f2 v-mid pa1" }, [
            _vm._v("apps"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }