
















import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

import profile_png from '@/assets/profile.png'

@Component
export default class TheNetworkProfile extends Vue {
  @Getter networkId!: any
  @Getter currentNetwork!: any
  @Action changeNetwork!: Function
  @Action resetAllStates!: Function

  get networks() {
    return this.$store.getters.networks
  }

  get networksMenu() {
    let menu: any = []
    if (this.networks) {
      this.networks.forEach((element: any) => {
        const img = (element.network.logoImage && element.network.logoImage.thumbnailURL) || profile_png
        const obj = {
          name: this.networkItem(element.network.name, img, element.network.id),
          id: element.network.id,
          onClick: () => {
            this.changeNetworkId(element)
          },
        }
        const filterdList = menu.filter((ele: any) => ele.id === obj.id) || []
        if (filterdList.length === 0) menu.push(obj)
      })
    }
    if (menu && menu.length === 1) return []
    return menu
  }

  networkItem(name: string, img: string, id: string) {
    return `<div :key="${id}" class="br2 flex justify-between items-center w-100 ">
              <div class="flex items-center justify-between w-100">
				<img class="w2 h2 mr2 item-image" src="${img}" >
                <div class="flex items-center w-100">
                  <div class="network-name-dropdown f6"> ${name} </div>
                </div>
              </div>
			  ${this.selectedNetwork(id)}
            </div>
            `
  }

  get currentNetworkImage() {
    return get(this.currentNetwork.network, 'logoImage.thumbnailURL', null)
  }

  get currentNetworkName() {
    return this.currentNetwork.network.name
  }

  currentNetworkType(networkId: string) {
    const currentSelectedNetwork = this.networks.find((network: any) => network.network.id === networkId) // Uh oh!
    const networkType = get(currentSelectedNetwork, 'network.networkType', '')
    if (['YOUTUBE_METERED', 'YOUTUBE_ENTERPRISE', 'YOUTUBE_PRO'].includes(networkType)) {
      return true
    }
    return false
  }

  selectedNetwork(id: string) {
    return this.currentNetwork.network.id === id
      ? `<i class="w2 material-icons adori-red">check_circle</i>`
      : '<i class="w2 material-icons adori-red">radio_button_unchecked</i>'
  }

  async changeNetworkId(networks: any) {
    if (this.networkId !== networks.network.id && !this.currentNetworkType(networks.network.id)) {
      localStorage.setItem('networkId', networks.network.id)
      localStorage.setItem('network', JSON.stringify(networks))
      this.$router.push('/overview')
      window.location.reload()
    }
    if (this.networkId !== networks.network.id && this.currentNetworkType(networks.network.id)) {
      localStorage.setItem('networkId', networks.network.id)
      localStorage.setItem('network', JSON.stringify(networks))
      this.$router.push('/home/podcasts')
      window.location.reload()
    }
  }
}
