

















import { ADORI_SSO_BASE_URL } from '@/constants'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

@Component
export default class LoginButton extends Vue {
  @Getter youtubeStep!: any

  @Action loadUser!: any
  @Action setSession!: any
  @Action getNetworkUsers!: any
  @Action getPermissions!: any

  youtubeAuthHandle: any = null
  loading = false

  async signIn() {
    this.youtubeStep === 3 ? sessionStorage.setItem('youtubeAuth', 'YES') : null
    this.youtubeAuthHandle = window.open(
      `${ADORI_SSO_BASE_URL}/logout?redirect_uri=${encodeURIComponent(window.origin + '/login')}`,
      'youtubeAuthHandle',
      'width=500,height=715'
    )
    const timer = setInterval(async () => {
      if (get(this.youtubeAuthHandle, 'closed', null)) {
        clearInterval(timer)
        if (localStorage.getItem('id_token')) {
          this.youtubeStep === 3 && sessionStorage.removeItem('youtubeAuth')
          const token: any = localStorage.getItem('id_token')
          const expiry: any = localStorage.getItem('expires_at')
          this.setSession({
            token: token,
            expiry: expiry,
          })
          this.$store.commit('setInitialLoader', true)
          await this.loadUser()
          this.$store.commit('setInitialLoader', false)
        }
      }
    }, 1000)
  }
}
