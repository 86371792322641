var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BasePopupMenu",
    {
      ref: "profileMenu",
      attrs: { icon: "arrow_drop_down", items: _vm.profileMenu },
    },
    [
      _c("span", { staticClass: "f6 white bw0 mr1" }, [
        !_vm.error && !_vm.avatar.endsWith("null")
          ? _c("img", {
              staticClass: "br2 mr2 v-mid ba b--gray bw1",
              attrs: { src: _vm.avatar },
            })
          : _c(
              "i",
              {
                staticClass:
                  "mr2 material-icons v-mid custom-size br2 ba b--gray bw1",
              },
              [_vm._v("account_box")]
            ),
      ]),
      !_vm.error
        ? _c(
            "span",
            { staticClass: "dn dib-l f6 light-gray mw5 truncate v-mid" },
            [_vm._v(_vm._s(_vm.username))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }